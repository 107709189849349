.pageContainer {
  width: 100%;
}

.banner {
  max-width: 100%;
  width: 100%;
  padding: 7.5%;
  margin: 0;
  background-size: cover;
}

.bannerTitle {
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  color: #F9F9F1;
}

.bannerContent {
  margin-top: 50px;
}

.bannerImage {
  width: 100%;
  height: 288px;
}

.bannerSubtitle {
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: #EEE9B6;
}

.bannerDescription {
  margin-top: 25px;
  font-size: 16px;
  line-height: 24px;
  color: #F9F9F1;
}

.bannerButton {
  margin-top: 50px;
}

.whatWeDoContainer1 {
  padding: 4vh 6vw;
  background-color: #FDFBF3;
}

.whatWeDoHeader {
  text-align: center;
  margin-bottom: 97px;
}

.whatWeDoSmallSubtitle {
  font-weight: 700;
  font-size: 24px;
}

.whatWeDoSubtitle {
  font-weight: 700;
  font-size: 36px;
}

.whatWeDoSubtitleDesc {
  margin-top: 40px;
  margin-bottom: 72px;
  font-weight: 400;
  font-size: 16px;
  color: #58585B;
}

.whatWeDoSponsorOthers {
  font-weight: 400;
  font-size: 16px;
  color: #808284;
}

.whatWeDoContainer2 {
  padding: 4vh 6vw;
  background-color: rgba(255, 255, 255, 0.7);
}

.carouselItemContainer {
  display: flex;
  margin-bottom: 64px;
  gap: 5%;
}

/* Client Styles START */

.clientContainer {
  border: 2px solid #B4D1C5;
  border-radius: 24px;
  width: 25%;
  height: 510px;
  overflow: hidden;
}

.clientProfilePictureContainer {
  height: 40%;
}

.clientProfilePicture {
  width: 100%;
  height: 100%;
}

.clientContentContainer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
  background: white;
}

.clientDescriptionContainer {
  margin-bottom: 20px;
}

.clientTitle {
  margin-top: 16px;
  font-weight: 700;
  font-size: 16px;
}

.clientDescription {
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  height: 95px;
}

.clientReport {
  margin-top: 32px;
  font-weight: 700;
  font-size: 12px;
}

.clientReportLight {
  font-weight: 400;
}
/* Client Styles END */

.berbagiHatiDisclaimerTitle {
  margin-top: 52px;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}

.berbagiHatiDisclaimer {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

.whatWeDoContainer3 {
  padding: 4vh 6vw;
  background-color: #FDFBF3;
}

.applyBerbagiHatiHeader {
  font-weight: 400;
  font-size: 16px;
  color: #808284;
  margin-bottom: 12px;
}

.applyBerbagiHatiCampaign {
  font-weight: 700;
  font-size: 24px;
}

.applyBerbagiHatiDescription {
  font-weight: 400;
  font-size: 16px;
  color: #58585B;
  margin-bottom: 60px;
}

.whatWeDoContainer4 {
  padding: 4vh 6vw;
  background-size: cover;
}

.tslDescription {
  margin-top: 40px;
  margin-bottom: 80px;
  font-weight: 400;
  font-size: 16px;
  color: #58585B;
}

.tslTestimonyTitle {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 24px;
}

/* Thumbnail Styles START */

.thumbnailContainer {
  width: 30%;
  overflow: hidden;
}

.thumbnailContainer:hover {
  cursor: pointer;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
}

/* Thumbnail Styles END */

.getInvolvedContainer {
  padding: 4vh 6vw;
  background-color: #ECF3F0;;
}

.getInvolvedSubtitle {
  font-weight: 700;
  font-size: 36px;
}

.getInvolvedDescription {
  margin-top: 40px;
  margin-bottom: 115px;
  font-weight: 400;
  font-size: 16px;
}

.getInvolvedCarouselTextContainer {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.getInvolvedCarouselTextTitle {
  font-weight: 700;
  font-size: 24px;
}

.getInvolvedCarouselTextDescription {
  font-size: 400;
  font-size: 16px;
}

/* Tablet */
@media only screen and (max-width: 991px) {
  /* Client Styles START */
  .clientContainer {
    width: 320px;
  }
  /* Client Styles END */

  /* Thumbnail Styles START */
  .thumbnailContainer {
    width: 320px;
  }
  /* Thumbnail Styles END */

  .carouselItemContainer {
    flex-direction: column;
  }

  .getInvolvedCarouselImage {
    width: 300px;
    height: 300px;
  }

  .getInvolvedCarouselTextContainer {
    gap: 15px;
  }


}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .bannerTitle {
    font-size: 42px;
  }

  .bannerSubtitle {
    font-size: 32px;
  }

  .bannerDescription {
    font-size: 14px;
  }
}
