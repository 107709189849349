.header {
  overflow-y: hidden;
  overflow-x: hidden;
  border-bottom: 0.5px solid #B0B0B0;
}

.row {
  padding: 16px 0;
  font-size: 16px;
  font-weight: 700;
  color: #58585B;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.image {
  transform: rotate(-90deg);
  transition: all 0.4s;
}

.imageExpanded {
  transform: rotate(0deg);
  transition: all 0.4s;
}

.imageNotExpanded {
  transform: rotate(-90deg);
  transition: all 0.4s;
}

.body {
  max-height: 0;
  padding: 0;
  transition: all 0.3s;
}

.bodyExpanded {
  max-height: 1000px;
  padding: 0 0 16px 0;
  transition: all 0.3s;
}

.bodyNotExpanded {
  max-height: 0;
  padding: 0;
  transition: all 0.3s;
}
