.container {
  width: fit-content;
  height: fit-content;
  position: relative;
  z-index: 0;
  cursor: pointer;
}

.inputField {
  height: 100%;
  padding: 8px 20px;
  border: 1px solid #b0b0b0;
  border-radius: 12px;
  background-color: white;
  display: flex;
  gap: 25px;
  justify-content: space-between;
}

.options {
  background-color: #f4f4f4;
  height: fit-content;
  width: 100%;
  margin-top: 8px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  overflow: hidden;
  word-wrap: break-word;
  position: absolute;
  box-shadow: 1px 1px 12px 0px #0000000d;
}

.container:focus > .options {
  visibility: visible;
}

.container:focus {
  z-index: 2;
}

.option {
  padding: 8px 20px;
  text-transform: capitalize;
}

.option:hover {
  background-color: #ffffff;
}

.option.selected {
  background-color: #ffffff;
  color: #4a8870;
}

.option:first-of-type {
  border-radius: 10px 10px 0 0;
}

.option:last-of-type {
  border-radius: 0 0 10px 10px;
}
