.contain {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin {
  height: 150px;
  width: 150px;
  border: 10px #A7D59B solid;
  border-radius: 75px;
  border-top-color: #4A8870;
  animation: spinner 0.7s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

