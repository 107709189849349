.titleRow {
  padding: 16px 42px 0 128px;
  background-color: #F9F9F1;
  align-items: flex-end;
}

.titleCol1 {
  margin-bottom: 64px;
}

.description {
  margin-top: 48px;
  max-width: 480px;
  font-size: 16px;
}

.disclaimerTitle {
  margin-top: 50px;
  font-weight: 700;
}

.berbagiHatiIllust {
  height: 360px;
  width: 40vw;
}

.row {
  margin-top: 68px;
}

.filterTitle {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
}

.filters {
  margin-bottom: 15px;
}

.client {
  width: 33%;
  margin-bottom: 15px;
  padding: 15px;
}

.sortByPanel {
  margin-bottom: 48px;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.sortByName {
  margin-right: 20px;
}

.clients {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
}

.customCard {
  margin-bottom: 30px;
}

.progress {
  max-width: 240px;
  margin-top: 32px;
  font-size: 12px;
}

.progressBold {
  font-weight: 700;
}

.numDonation {
  margin: 20px 0;
  font-size: 12px;
}

.donateRow {
  margin: 0;
}

.fundOthersDonateBtn {
  margin-right: 90px;
}

.share {
  cursor: pointer;
}

.noResult {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 241px 0;
}

.noResultImg {
  width: 329px;
}

.noResultTitle {
  margin: 80px 0 16px 0;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.noResultDesc {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

/* Tablet */
@media only screen and (max-width: 991px){
  .description {
    max-width: none;
  }

  .clients {
    margin-top: 15px;
  }

  .client {
    width: 50%;
  }

  .berbagiHatiIllust {
    max-height: 360px;
    height: auto;
    width: 100%;
  }

  .titleRow {
    padding-left: 32px;
    padding-right: 32px;
    flex-direction: column;
    margin: 0;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px){
  .sortByPanel {
    margin-top: 30px;
    justify-content: flex-start;
  }

  .client {
    width: 100%;
    padding: 0;
  }
}
