.content {
  margin-top: 48px;
}

.title {
  margin-bottom: 32px;
}

.sectionDescription1 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 48px;
}

.sectionDescription2 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
}

.reverseWrap {
  flex-wrap: wrap-reverse;
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .contactUsImage {
    width: 100%;
  }
}

.buttonRow {
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
}
