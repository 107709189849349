.maintenancePageContainer {
  background-color:#4A8870;
  min-height: 100vh;
}

.maintenanceContentContainer {
  background-color: white;
  padding: 50px;
  border-radius: 20px;
}

.header {
  margin-top: 83px;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 48px;
  color: #4A8870;
}

.description {
  font-weight: 400;
  font-size: 16px;
}

.socialMediaContainer {
  margin-top: 175px;
  display: flex;
  gap: 32px;
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .header {
    font-size: 40px;
  }

  .maintenanceImgContainer {
    display: none;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .maintenanceContentContainer {
    padding: 20px;
  }
}
