.header {
  margin-bottom: 56px;
}

.visionMissionSection {
  padding: 4vh 6vw;
  background-color: #ECF3F0;
  text-align: center;
  position: relative;
}

.visionMissionBackground {
  position: absolute;
  top: 5vh;
  left: 25vw;
  width: 50vw;
  height: 100%;
  z-index: 0;
}

.visionMissionContentContainer {
  position: relative;
  z-index: 1;
}

.visionMissionHeader {
  font-size: 20px;
  font-weight: 500;
}

.visionMissionDescriptionContainer {
  display: flex;
  justify-content: center;
}

.visionMissionDescription {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 48px;
  max-width: 500px;
}

.foundationSection {
  padding: 4vh 6vw;
  background-color: #F9F9EE;
  position: relative;
  overflow: hidden;
}

.foundationBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 0;
}

.foundationContent {
  position: relative;
  z-index: 10;
}

.foundationHeader {
  color: #2D5C4A;
  margin-bottom: 32px;
}

.foundationDescription {
  color: #2D5C4A;
  max-width: 696px;
  margin-bottom: 80px;
}

.foundationBeliefsContainer {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.ellipseSmallContainer {
  width: 320px;
  height: 320px;
  background-color: #DEE9D5;
  border-radius: 50%;
  padding: 65px;
  color: #2D5C4A;
  font-size: 19px;
  font-weight: 500;
}

.ellipseLargeContainer {
  width: 350px;
  height: 350px;
  background-color: #DEE9D5;
  border-radius: 50%;
  padding: 65px;
  color: #2D5C4A;
  font-size: 20px;
  font-weight: 500;
}

.campaignSection {
  padding: 4vh 6vw;
  background-color: #ED9C76;
  display: flex;
  justify-content: space-between;
}

.campaignDescription {
  color: white;
  font-size: 24px;
  font-weight: 700;
  max-width: 560px;
}

.campaignHashtag {
  max-width: 413px;
  font-size: 64px;
  font-weight: 700;
  text-align: right;
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: transparent;
}

.ourWorkContainer {
  padding: 4vh 6vw;
}

.ourWorkTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.ourWorkSubtitle {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 32px;
}

.intervention {
  margin-top: 72px;
}

.ourWorkSubtitleDesc {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 48px;
}

.linkedButton:hover {
  text-decoration: none;
}

/* Poster Styles START */
.posterContainer {
  height: 750px;
  background-color: #DEE9D5;
  padding: 24px;
}

.posterImgContainer {
  text-align: center;
}

.posterImg {
  width: 100%;
  height: 280px;
}

.posterContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 420px;
}

.posterTitle {
  font-size: 20px;
  font-weight: 700;
  margin-top: 35px;
  margin-bottom: 15px;
  height: 60px;
}

.posterDescription {
  font-size: 16px;
  font-weight: 400;
}
/* Poster Styles END */

.brandingContainer {
  position: relative;
  padding: 4vh 6vw;
}

.brandingBackground {
  position: absolute;
  top: 0;
  left: -6vw;
  width: 106vw;
  height: 100%;
  z-index: 0;
}

.brandingContent {
  position: relative;
  z-index: 1;
}

.brandingSmallTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.brandingTitle {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
}

.brandingDescription {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 64px;
  padding: 0 20%;
}

.brandingLogoContainer {
  display: flex;
  justify-content: space-around;
}

.brandingLogoItem {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 33%;
}

.brandingName {
  font-size: 16px;
  color: #2D5C4A;
}

.questionsContainer {
  position: relative;
  margin-top: -60px;
}

.questionsBackground {
  position: absolute;
  left: -6vw;
  width: 106vw;
  height: 100%;
  z-index: -1;
}

.questionsContentContainer {
  padding: 4vh 6vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Window Styles START */
.windowWrapper {
  display: flex;
  justify-content: center;
}

.windowWrapper:first-child {
  margin-top: 80px;
}

.windowWrapper:last-child {
  margin-bottom: 80px;
}

.windowContainer {
  border-radius: 20px;
  overflow: hidden;
  max-width: 700px;
}

.windowTabContainer {
  background-color: #2D5C4A;
  padding: 5px 15px;
  display: flex;
  gap: 10px;
}

.windowContentContainer {
  background-color: #F4F4F4;
  padding: 40px 64px;
}

.windowTitle {
  color: #2D5C4A;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
}

.windowDescription {
  font-size: 16px;
  font-weight: 400;
}

/* Window Styles END */

/* Tablet */
@media only screen and (max-width: 991px) {
  .visionMissionBackground {
    top: 8vh;
    left: 0;
    height: 400px;
    width: 100vw;
  }

  .foundationDescription {
    margin-bottom: 15px;
  }

  .foundationBeliefsContainer {
    display: block;
  }

  .ellipseSmallContainer, .ellipseLargeContainer {
    border-radius: 16px;
    padding: 24px;
    width: auto;
    height: auto;
    margin-top: 46px;
  }

  .ellipseSmallContainer:last-child {
    margin-bottom: 85px;
  }

  .campaignSection {
    display: block;
  }

  .campaignHashtag, .campaignDescription {
    max-width: none;
  }

  .campaignHashtag {
    font-size: 50px;
  }

  .posterContainer {
    margin-bottom: 15px;
  }
}


/* Smartphone */
@media only screen and (max-width: 576px) {
  .header, .visionMissionHeader {
    text-align: left;
  }

  .visionMissionDescription {
    text-align: left;
    font-size: 20px;
  }

  .visionMissionDescriptionContainer {
    display: block;
  }

  .visionMissionBackground {
    top: 20vh;
    left: 5vw;
    height: 200px;
    width: 90vw;
  }

  .campaignHashtag {
    margin-top: 46px;
  }

  .intervention {
    margin-top: 15px;
  }

  .brandingDescription {
    padding: 0;
  }

  .brandingBackground {
    display: none;
  }

  .brandingContainer {
    border-top: 1px solid #2D5C4A;
    border-bottom: 1px solid #2D5C4A;
  }

  .brandingImage1 {
    width: 18vw;
    height: 57px;
  }

  .brandingImage2 {
    width: 18vw;
    height: 31px;
  }

  .questionsContainer {
    margin-top: 0px;
  }

  .windowWrapper:first-child {
    margin-top: 0;
  }

  .windowTabContainer {
    padding: 10px 20px;
  }

  .windowContentContainer {
    padding: 20px;
  }

  .windowTitle {
    margin-bottom: 15px;
  }
}
