.donorInformationContainer {
  border-left: 2px solid #d9d9d9;
}

.donorInformationDesc {
  font-size: 16px;
  color: #58585b;
  margin-bottom: 56px;
}

/* Donation Profile */
.donationProfileContainer {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 15px;
}

.donationProfileHeader {
  display: flex;
  justify-content: space-between;
  min-height: 24px;
}

.donationProfileImg {
  width: 100%;
  max-width: 112px;
  height: 136px;
}

.donationProfileDescription {
  margin-top: 10px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #58585b;
}

.donationAmount {
  font-size: 16px;
  font-weight: 400;
  color: #58585b;
}

/* General Donation */
.generalDonationContainer {
  margin-top: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 15px;
  background-color: #f4f4f4;
}

.generalDonationImg {
  width: 100%;
  max-width: 112px;
  height: 100%;
}

.generalDonationHeader {
  display: flex;
  justify-content: space-between;
  min-height: 24px;
}

.addGeneralDonationBtn {
  width: 100%;
  max-width: 112px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  border-radius: 12px;
  font-weight: bold;
  font-size: 66px;
}

.addGeneralDonationBtn {
  cursor: pointer;
}

.generalDonationFormContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

/* Total Donation */
.totalDonationAmountContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  background-color: #f9f9f1;
  border-top: 1px solid #58585b;
  border-bottom: 1px solid #58585b;
  padding: 12px 32px;
}

.totalAmountText {
  font-size: 16px;
  font-weight: 400;
  color: #58585b;
}

.totalDonationAmount {
  font-size: 16px;
  font-weight: 700;
}

/* Available Payments */
.availablePaymentsContainer {
  margin-top: 65px;
  margin-bottom: 35px;
}

.availablePaymentMethodsImgContainer {
  display: flex;
  gap: 15px;
}

.availablePaymentMethodsImg {
  width: 32px;
  height: 32px;
}

/* Donation Form */
.donationFormContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 40px;
}

.donationFormFieldWidth {
  width: 100%;
}

.submitDonationContainer {
  margin-top: 73px;
}

/* QRIS Image */

.qrisImage {
  width: 500px;
  height: 500px;
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .donorInformationContainer {
    border: none;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .qrisImage {
    width: 80vw;
  }
}
