/* Chart Container */
.chartContainer {
  height: fit-content;
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

/* Donut Chart */
.donutChartContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.donutChart {
  height: fit-content;
}

.totalCost {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.totalCostLabel {
  font-weight: 700;
}

.percentage {
  fill: #ffffff;
}

/* Legend Chart */
.legendContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.legendHeader {
  padding: 11px 20px;
  margin: 0;
  border-width: 2px 2px 0;
  border-style: solid;
  border-color: #dddddd;
}

.legendHeaderText {
  padding: 0;
  color: #808284;
  font-weight: 700;
}

.colorBoxContainer {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 11px;
}

.colorBox {
  width: 11px;
  height: 11px;
}

.legendContent {
  background-color: white;
  margin: 0;
  border: 2px solid #dddddd;
}

.legendContentText:nth-child(even) {
  background-color: #f4f4f4;
}

.legendContentText {
  width: 100%;
  padding: 12px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
}
