.popupCard {
  width: 100%;
  border: 0;
}

.popupImage {
  width: 50%;
  margin: 0 auto;
}

.textfield {
  border: 1px solid #4A8870;
  box-sizing: border-box;
  border-radius: 12px;
}

.submitButton {
  background-color: #4A8870;
  border-radius: 0px 12px 12px 0px;
}

.submitButton:hover {
  background-color: #2d5c4a;
}

.modalHeader {
  border-bottom: 0;
}
