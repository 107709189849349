.reverseWrap {
  flex-wrap: wrap-reverse;
}

.urgent {
  margin-bottom: 32px;
}

.urgentNumber {
  line-height: 45px;
  margin-right: 32px;
  margin-bottom: 48px;
  margin-left: 15px;
  font-size: 48px;
  font-weight: 700;
  color: #38383C;
}

.insideUse {
  font-size: 14px;
  font-weight: 400;
  color: #38383C;
}

.insideTitle {
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: 700;
  color: #38383C;
}

.inside {
  min-width: 175px;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 300;
  color: #38383C;
}

.urgentHelpImage {
  width: 500px;
  height: 324px;
}

/* Small Laptops */
@media only screen and (max-width: 1199px) {
  .urgentHelpImage {
    margin-left: 0;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .urgentHelpImage {
    width: 100%;
  }
}
