.container {
  display: flex;
}

.progressBar {
  height: 10px;
  width: 85%;
  margin-right: 5%;
  border-radius: 10px;
  background: #D9D9D9;
}

.inside {
  height: 100%;
  border-radius: 10px;
  background: #58585B;
}

.progress {
  font-size: 12px;
  line-height: 10px;
  width: 10%;
  text-align: right;
}
