.header {
  text-align: center;
}

.boardMemberHeader {
  text-align: center;
  margin-bottom: 56px;
}

.boardMemberSection {
  position: relative;
  margin-bottom: 2vh;
}

.boardMemberSectionBackground {
  position: absolute;
  width: 100vw;
  height: 115%;
  margin-left: -6vw;
  margin-top: -4vh;
  top: 0;
  left: 0;
  z-index: -1;
}

.boardMemberBackground {
  width: 100%;
  height: 100%;
}

.memberDivision {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
}

/* Member Profile Styles START */

.memberProfilePicture {
  width: 220px;
  height: 220px;
}

.memberProfileFullName {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #38383c;
}

.memberPosition {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.memberProfileLinks {
  margin-top: 42px;
  margin-bottom: 42px;
  display: flex;
  gap: 32px;
}

.memberProfileLinks a:hover svg {
  fill: #38383c;
}

/* Member Profile Styles END */

.joinTheTeamSection {
  margin-left: -6vw;
  margin-bottom: -4vh;
  padding: 0 12vw 4vh 6vw;
  width: 100vw;
  background-color: #4a8870;
  color: white;
}

.joinTheTeamTitle {
  font-size: 24px;
  font-weight: 700;
  padding-top: 80px;
  margin-bottom: 16px;
}

.joinTheTeamContent {
  position: relative;
  margin-top: 15px;
}

.joinTheTeamBtn {
  top: 15px;
  position: absolute;
}

.joinTheTeamBackgroundImg {
  margin-left: -6vw;
  width: 100vw;
}

/* Small Laptops */
@media only screen and (max-width: 1199px) {
  .joinTheTeamBtn {
    top: 0;
  }

  .joinTheTeamBackgroundImg {
    position: static;
  }
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .boardMemberSectionBackground {
    height: 115%;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .boardMemberSectionBackground {
    background-color: #f9f9f1;
    height: 100%;
  }

  .boardMemberSection {
    margin-bottom: 0;
  }

  .joinTheTeamTitle {
    padding-top: 20px;
  }
}
