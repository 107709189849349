body {
  font-family: 'Poppins';
}

a {
  font-size: 16px;
  color: #4a8870;
}

a:hover {
  color: #4a8870;
  text-decoration: underline;
}

h1 {
  font-weight: bold;
  font-size: 36px;
  color: #38383c;
}

h2 {
  font-weight: 700;
  font-size: 20px;
  color: #38383c;
}

h3 {
  font-weight: 700;
  font-size: 16px;
  color: #38383c;
}

p {
  font-size: 16px;
  font-weight: 400;
}

hr {
  margin: 15px 0;
  border: 0.5px solid #b0b0b0;
}

/* Note: IOS Support - Remove default rounded input tag */
/* Note: IOS Support - Overflowing width fix */
input {
  -webkit-border-radius: 0;
  border-radius: 0;
  width: 100%;
}

.page-container {
  padding: 4vh 6vw;
  min-height: 77vh;
}

/* bootstrap override START */

/* override bootstrap bg-light to Merajut Hati's theme */
.bg-light {
  background-color: #f4f4f4 !important;
}

.dropdown-item:active {
  background-color: #4a8870;
}

.btn:focus,
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
  border: none;
  background-color: #2d5c4a;
}

.form-control:focus {
  border: 1px solid #4a8870;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #4a8870;
  border-color: none;
}

/* bootstrap override END */

/* Smartphone */
@media only screen and (max-width: 576px) {
  a {
    font-size: 14px;
  }

  h1 {
    font-size: 28px;
  }

  p {
    font-size: 14px;
  }

  button {
    font-size: 14px;
  }
}
