.header {
  overflow: hidden;
}

.row {
  padding: 20px 16px;
  background-color: #f4f4f4;
  border-top: 0.75px solid #000000;
  border-bottom: 0.75px solid #000000;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.image {
  transform: rotate(-90deg);
  transition: all 0.4s;
}

.imageExpanded {
  transform: rotate(0deg);
  transition: all 0.4s;
}

.imageNotExpanded {
  transform: rotate(-90deg);
  transition: all 0.4s;
}

.body {
  max-height: 0;
  padding: 0px 40px;
  transition: all 0.3s;
}

.bodyExpanded {
  max-height: 1000px;
  padding: 32px 40px;
  transition: all 0.3s;
}

.bodyNotExpanded {
  max-height: 0;
  padding: 0px 40px;
  transition: all 0.3s;
}
