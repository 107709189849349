.container {
  display: flex;
  justify-content: space-between;
  background-color: #f4f4f4;
}

.logoContainer {
  margin-left: 15px;
}

.mhLogo {
  width: 150px;
  height: 90px;
}

.navMenu {
  display: flex;
  margin-top: 35px;
}

.clickableDropdown,
.link {
  cursor: pointer;
  min-width: 160px;
  text-align: center;
}

.dropdown,
.link {
  margin: 0 15px 0 0;
}

.dropdown,
.clickableDropdown {
  height: 100%;
}

.dropdownName {
  margin: 0 10px 0 0;
}

.dropdownItemContainer {
  position: absolute;
  z-index: 1000;
  display: none;
  background: #f4f4f4;
  border-radius: 0px 0px 10px 10px;
  border-top: 4px solid #4a8870;
  min-width: 170px;
}

.clickableDropdown:hover ~ .dropdownItemContainer,
.dropdownItemContainer:hover {
  display: block;
}

.dropdownItemContainer .linkName:hover {
  background-color: #d9d9d9;
}

.dropdown {
  color: #4a8870;
  position: relative;
}

.linkName {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.linkName:last-child {
  border-radius: 0px 0px 10px 10px;
}

.linkName:hover {
  visibility: visible;
  text-decoration: none;
}

.navbar-toggler {
  border: none;
}

/* Small Tablet */
@media only screen and (max-width: 767px) {
  .container,
  .navMenu {
    display: block;
  }

  .navMenu {
    margin: 0;
    padding: 0 1rem;
  }

  .dropdown {
    margin: 0;
  }

  .dropdownItemContainer {
    position: static;
    display: none;
  }

  .clickableDropdown:hover ~ .dropdownItemContainer,
  .dropdownItemContainer:hover {
    display: block;
  }

  .dropdownItemWrapper {
    position: static;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .mhLogo {
    width: 100px;
    height: 40px;
  }
}
