.container {
  height: 46px;
  width: 298px;
}

.toggleWrapper {
  height: 100%;
  width: 100%;
  border: 1px solid;
  border-color: #f4f4f4;
  border-radius: 12px;
  background-color: #f4f4f4;
  display: flex;
  cursor: pointer;
}

.errorToggleWrapper {
  border-color: #d35f5f;
}

.toggle {
  height: 100%;
  width: 100%;
}

.toggleOption {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0b0b0;
  font-size: 12px;
  font-weight: 400;
  transition: 0.3s;
}

.activeToggle {
  background-color: #58585b;
  color: #f4f4f4;
}

.errorToggle {
  color: #d35f5f;
}

.toggleFirst {
  border-radius: 12px 0 0 12px;
}

.toggleSecond {
  border-radius: 0 12px 12px 0;
}

.separator {
  height: 100%;
  width: 0;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: #b0b0b0;
}

.activeSeparator {
  border-color: #58585b;
}

.errorSeparator {
  border-color: #d35f5f;
}

.errorMessage {
  margin: 4px 24px;
  color: #d35f5f;
  font-size: 8px;
}
