.navigationButton {
    border: 2px solid #4A8870;
    border-radius: 50%;
    padding: 10px 15px;
    margin: 0 20px;
    cursor: pointer;
}

.carouselWrapper div ol {
    margin: 0;
    width: fit-content;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 25px);
}

.carouselWrapper div ol li {
    background-color: #2d5c4a;
}

.carouselFooterContainer {
  display: flex;
  justify-content: space-between;
}

.carouselFooterContainer div:only-child {
  margin-left: auto;
}
