.card:hover .title {
  color: #4A8870;
}

.card:hover .button {
  visibility: visible;
}

.cardImage {
  height: 240px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.button {
  visibility: hidden;
  position: relative;
  top: -64px;
  width: fit-content;
  padding: 10px 24px;
  border-radius: 85px;
  background-color: #F4F4F4;
  font-size: 12px;
  color: #38383C;
  cursor: pointer;
}

.imgBackground {
  height: 240px;
  background-color: #D9D9D9;
}

.title {
  margin-top: -24px;
  font-size: 16px;
  font-weight: 700;
  color: #38383C;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.desc {
  height: 95px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #38383C;
  white-space: pre-wrap;
}
