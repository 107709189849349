.headerContainer {
  padding-left: 6vw;
  margin-left: -6vw;
  margin-top: -4vh;
  padding-right: 6vw;
  width: 100vw;
  height: 280px; /* background image height */
  background-color: #ed9c76;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
}

.title {
  margin-top: 4vh;
  color: white;
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.category {
  cursor: pointer;
}

.categoryActive {
  font-weight: 700;
}

/* Small Tablet */
@media only screen and (max-width: 767px) {
  .headerImg {
    width: 350px;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .headerContainer {
    margin-bottom: 15px;
  }

  .headerContainer {
    height: auto;
    flex-direction: column;
  }

  .headerImg {
    height: 280px;
    width: 100%;
  }
}
