.expandable {
  height: 64px;
  width: 64px;
  border-radius: 32px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0.75px solid #B0B0B0;
  background-color: transparent;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.expandable:hover {
  transition: all 0.3s;
  width: fit-content;
  border: 1px solid #4A8870;
  justify-content: center;
  align-items: center;
  background-color: #4A8870;
}

.expandable:hover .firstLine{
  display: block;
}

.expandable:hover .secondLine{
  display: block;
}

.expandable:hover .icon {
  filter: invert(100) brightness(100);
}

.icon {
  width: 24px;
  height: 24px;
}

.desc {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #4A8870;
}

.firstLine {
  display: none;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 400;
}

.secondLine {
  display: none;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 700;
}

@keyframes expand {
  100% {
    width: fit-content;
  }
}
