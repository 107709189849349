.title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
}

.reverseWrap {
  flex-wrap: wrap-reverse;
}

.donation {
  margin-bottom: 96px;
  font-weight: 400;
  font-size: 16px;
}

.donate {
  font-weight: 700;
}

.update {
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 16px;
}

.separator {
  height: 11px;
}

.col {
  padding-left: 0;
  display: flex;
  flex-direction: column;
}

.thankYouImage {
  height:324px;
  width:500px;
}

.button {
  margin-bottom: 11px;
  padding: 10px 24px 10px 24px;
  background-color: #4A8870;
  color: #FFFFFF;
  border-radius: 85px;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
  cursor: pointer;
}

.button:hover {
  background-color: #EEE9B6;
  color: #4A8870;
  text-decoration: none;
}

.thankYouIcons {
  margin-right: 20px;
  filter: brightness(0) invert(1);
}

.button:hover .thankYouIcons {
  filter: none;
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .thankYouImage {
    width: 100%;
  }
}
