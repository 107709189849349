/* Header Styles Start*/

.header {
  height: 52px;
  padding: 5px 50px 5px 5px;
  background-color: #4a8870;
  align-items: center;
  margin: 0;
}

.headerSpacer {
  border: 1px solid #ffffff;
  transform: rotate(90deg);
}

.header a {
  color: #ffffff;
  font-weight: 700;
}

.header a:hover {
  color: #eee9b6;
}

.languageSelector {
  color: #ffffff;
  font-weight: 300;
}

.activeLanguageSelector {
  color: #ffffff;
  font-weight: 700;
}

.languageSelector:hover {
  color: #eee9b6;
}

.activeLanguageSelector:hover,
.languageSelector:hover {
  cursor: pointer;
}

.headerContainer:last-child {
  text-align: right;
}

.headerContainer > *:first-child {
  margin: 0 15px 0 0;
}

.headerContainer > *:last-child {
  margin: 0 0 0 15px;
}

.headerContainer * img {
  margin: 0 12px 0 0;
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .header {
    padding: 5px;
  }

  .headerContainer:first-child {
    display: flex;
    justify-content: space-between;
  }

  .headerSpacer,
  .headerContainer:last-child {
    display: none;
  }
}

/* Header Styles End*/

/* Footer Styles Start */

.footer {
  height: fit-content;
  margin: 0;
  padding: 43px 60px 100px 15px;
  background-color: #f4f4f4;
  box-sizing: border-box;
}

.footer .copyright {
  color: #4a8870;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.footer .newsletter * {
  display: flex;
  justify-content: center;
}

.footerMobileLinks {
  background-color: #4a8870;
  padding: 36px 72px;
}

.footerMobileLinks > div {
  display: flex;
  justify-content: space-between;
}

.footerMobileLinks > div:first-child {
  margin-bottom: 28px;
}

.footerMobileLinks > div a:last-child {
  text-align: right;
}

.footerMobileLinks a {
  color: white;
}

.newsletterHeader {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #4a8870;
}

.newsletterDescription {
  font-size: 12px;
  color: #4a8870;
}

.newsletterInputGroup {
  width: 60%;
  margin-bottom: 1rem; /* similar to p tag default margin */
}

.footerNewsLetterTextfield {
  border: 1px solid #4a8870;
  box-sizing: border-box;
  border-radius: 12px;
}

.submitButton {
  background-color: #4a8870;
  border-radius: 0px 12px 12px 0px;
}

.footer .socialMedia {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
}

.footer .socialMedia a {
  padding: 5px;
}

.footer .socialMedia a img {
  margin: 0;
  width: 24px;
  height: 24px;
}

.footer .socialMedia a:hover svg {
  fill: #2d5c4a;
}

.footer .links {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 24px;
}

.footer .links a {
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .footer {
    text-align: center;
    gap: 24px;
  }

  .footer .copyright {
    gap: 24px;
  }
}

/* Small Tablet */
@media only screen and (max-width: 767px) {
  .footer {
    padding: 43px 20px;
  }

  .footer .links {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .footer .links > div {
    align-items: center;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .newsletterInputGroup {
    width: 100%;
  }
}
/* Footer Styles End */
