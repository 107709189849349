.description {
  margin-top: 40px;
  margin-bottom: 56px;
  font-size: 16px;
}

.filterTitle {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
}

.filters {
  margin-bottom: 15px;
}

.cardImage {
  margin-right: 18px;
  height: 16px;
  width: 16px;
  fill: #38383c;
}

.cardRow {
  margin-top: 17px;
  margin-left: 0px;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: #38383c;
  text-decoration: none;
}

.clearBtn {
  background-color: #d9ebe3;
  padding: 12px 24px;
  border-radius: 85px;
  font-size: 16px;
  font-weight: 500;
  color: #4a8870;
  cursor: pointer;
}

.provider {
  width: 33%;
  margin-bottom: 15px;
  padding: 15px;
}

.providers {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
}

.customCard {
  margin-bottom: 30px;
}

.noResult {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 241px 0;
}

.noResultImg {
  width: 329px;
}

.noResultTitle {
  margin: 80px 0 16px 0;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.noResultDesc {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .providers {
    margin-top: 15px;
  }

  .provider {
    width: 50%;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .provider {
    width: 100%;
    padding: 0;
  }
}
