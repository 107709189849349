.textHeader {
  font-size: 24px;
  font-weight: 700;
}

/* donate page*/
.donatePageContainer {
  min-height: 0;
  background-size: cover;
  background-position-x: center;
}

.donatePage {
  background-color: #fffffa;
  padding: 4vh 4vw calc(4vh + 40px);
  border-radius: 20px;
}

.donateDescription {
  color: #58585b;
  font-weight: 400;
}

.donationFormContainer {
  gap: 15px;
  margin: 0 0 70px 0;
}

.textOption {
  padding: 6px;
}

.donatePageImageContainer {
  min-height: 470px;
  display: flex;
  justify-content: center;
}

.donatePageImage {
  position: absolute;
  bottom: calc(-210px - 4vh);
}

/* other donation */
.otherDonation {
  min-height: fit-content;
  width: 100%;
  padding-top: 130px;
  padding-bottom: 130px;
  position: relative;
  margin-top: calc(-4vh - 40px);
  background-position: center;
  background-size: cover;
}

.otherDonationDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.otherDonationCard {
  background-color: #c1dbb3;
  height: 250px;
  width: 100%;
  margin-bottom: 16px;
  padding: 24px 36px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardText {
  color: #2d5c4a;
}

/* cost disbursement */
.costDisbursement {
  height: fit-content;
}

.dropdown {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.monthDropdownWidth {
  width: 120px;
}

.yearDropdownWidth {
  width: 170px;
}

.categoryDropdownWidth {
  width: 300px;
}

.costDisbursemetTable {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.tableRowHeader {
  background-color: #4a8870;
}

.tableRowOdd {
  background-color: #fcfcfc;
}

.tableRowEven {
  background-color: #f4f4f4;
}

.tableHeaderCell {
  color: #ffffff;
}

.tableCell {
  padding: 16px 48px;
  word-wrap: break-word;
}

.tableDate {
  padding: 16px 48px;
  word-break: keep-all;
}

/* Tablet */
@media only screen and (max-width: 992px) {
  .donatePageImage {
    width: 380px;
    bottom: -210px;
  }
}

/* Small Tablet */
@media only screen and (max-width: 768px) {
  .donatePageImageContainer {
    min-height: 250px;
  }

  .donatePageImage {
    width: 300px;
    bottom: -170px;
  }

  .costDisbursemetTable {
    display: none;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .otherDonation {
    padding-top: 4vh;
    padding-bottom: 4vh;
  }

  .otherDonationCard {
    height: fit-content;
  }

  .legendContainer {
    justify-content: center;
  }
}
