.getInvolvedDescription {
  margin-top: 40px;
  margin-bottom: 80px;
}

.getInvolvedItemContainer {
  margin-left: -6vw;
  padding: 0 6vw;
  width: 100vw;
  position: relative;
  display: flex;
}

.getInvolvedItemContainer:last-child {
  margin-bottom: -4vh;
}

.getInvolvedItemEven {
  background-color: #f9f9f1;
}

.getInvolvedItemOdd {
  background-color: #ecf3f0;
}

.getInvolvedItem {
  width: 100%;
}

.getInvolvedItemHeader {
  margin-top: 48px;
  font-size: 24px;
  font-weight: 700;
}

.getInvolvedItemHeaderNote {
  margin-top: 12px;
  font-size: 12px;
  font-weight: 400;
}

.getInvolvedItemDescription {
  max-width: 640px;
  margin-top: 32px;
  margin-bottom: 52px;
}

.getInvolvedItemButton {
  margin-bottom: 60px;
}

.getInvolvedItemImage {
  margin-right: -6vw;
  min-height: 324px;
  height: 100%;
  background-size: cover;
  background-position-x: center;
}

.linkedButton {
  display: inline-block;
}

.linkedButton:hover {
  text-decoration: none;
}

.beADonorImage {
  min-height: 450px;
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .getInvolvedItemImage {
    margin: 0 0 15px 0;
    height: auto;
  }
}
