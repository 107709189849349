.content {
  margin-top: 48px;
}

.sectionTitle {
  font-size: 20px;
  font-weight: 700;
  margin: 32px 0;
}

.lastUpdate {
  color: #b0b0b0;
}
