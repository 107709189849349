.spinnerHeight {
  height: 150px;
}

.spinnerWidth {
  width: 150px;
}

.spinnerBorder {
  border-width: 10px;
}

.spinner {
  border-color: #a7d59b;
  border-style: solid;
  border-radius: 50%;
  border-top-color: #4a8870;
  animation: spinner 0.7s infinite linear;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
