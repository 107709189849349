.container {
  padding-top: 8px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;
}

.button {
  margin-right: 16px;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  border: 1px solid #58585B;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonChecked {
  margin-right: 16px;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  border: hidden;
  background-color: #4A8870;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 8px;
}

.label {
  width: calc(100% - (18px + 16px));
  font-size: 12px;
  font-weight: 400;
}
