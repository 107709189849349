.screeningPageContainer {
  background-size: cover;
  background-position-x: center;
}

.screeningPage {
  background-color: white;
  padding: 4vh 4vw;
  border-radius: 20px;
}

.screeningPageImg {
  float: right;
  width: 416px;
  height: 100%;
}

.iconWithText {
  display: flex;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;
}

.iconWithText img {
  width: 16px;
}

.iconWithText div {
  font-size: 16px;
  font-weight: 400;
}

.headerOne {
  font-size: 14px;
  font-weight: 400;
}

.headerTwo {
  font-size: 20px;
  font-weight: 700;
}

/* Landing Page */
.landingImg {
  margin-left: 113px;
  width: 416px;
  height: 100%;
}

.section {
  margin: 48px 0;
}

.section:last-of-type {
  margin-bottom: 80px;
}

.reverseWrap {
  flex-wrap: wrap-reverse;
}

/* Question Page */
.pageIndicator {
  font-size: 16px;
  font-weight: 400;
  text-align: right;
}

.questionOptionContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.questionOption {
  width: fit-content;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 32px;
}

.radioInput {
  height: 24px;
  width: 24px;
  border: 1px solid #38383c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type='radio'] {
  height: 0;
  width: 0;
  opacity: 0;
}

input[type='radio']:checked + .radioControl {
  background: black;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.questionNavigationContainer {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 40px;
}

.prevNextNavigation {
  display: flex;
  gap: 40px;
}

.iconButtonHeight {
  height: 16px;
}

.iconButtonWidth {
  width: 16px;
}

/* demographic data */

.formGroupOne {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.formFirstRow {
  column-gap: 8px;
  padding: 0 15px;
}

.formFirstRow > div:first-child {
  padding: 0;
  flex: 2;
}

.formFirstRow > div:last-of-type {
  padding: 0;
  flex: 1;
}

.formGroupTwo {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.inputHeight {
  height: 34px;
}

.inputWidth {
  width: 100%;
}

.buttonWithSpinner {
  display: flex;
  align-items: center;
  gap: 5px;
}

.errorMessage {
  margin: 4px 0 4px 24px;
  color: #d35f5f;
  font-size: 8px;
}

/* Result */
.resultHeader {
  font-size: 24px;
  font-weight: 700;
}

/* spinner */
.spinnerWrapper {
  height: 38px;
  width: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  height: 25px;
  width: 25px;
  border: 3px #a7d59b solid;
  border-radius: 75px;
  border-top-color: #4a8870;
  animation: spinner 0.7s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* toast */
.toastWrapper {
  height: 50vh;
  display: flex;
  align-items: flex-end;
  position: fixed;
  bottom: 100px;
  right: 20px;
  overflow-y: auto;
  z-index: 1;
}

.toast {
  background-color: #d35f5f;
  width: 300px;
  height: 100px;
}

.toastHeader {
  display: flex;
  justify-content: space-between;
}

.toastHeaderText {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Small Laptops */
@media only screen and (max-width: 1199px) {
  .screeningPageImg {
    margin-left: 0;
    margin-top: 1vh;
    width: 100%;
    max-width: 416px;
  }

  .landingImg {
    margin-left: 0;
    margin-top: 1vh;
  }
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .screeningPageImg {
    width: 416px;
    height: 100%;
    float: left;
  }

  .section {
    margin: 2vh 0;
  }

  .section:last-of-type {
    margin-bottom: 4vh;
  }

  .formFirstRow > div:first-child {
    flex: 4;
  }

  .formFirstRow > div:last-of-type {
    flex: 3;
  }

  .question {
    height: 59.2px;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .screeningPageImg {
    width: 100%;
  }

  .landingImg {
    width: 100%;
  }

  .questionNavigationContainer {
    justify-content: space-between;
  }

  .formGroupTwo {
    margin-top: 16px;
  }

  .iconWithText {
    gap: 40px;
  }

  .question {
    height: 118.4px;
  }
}
