.container {
  height: 46px;
  width: 298px;
}

::placeholder {
  color: #b0b0b0;
}

.inputWrapper {
  background-color: #f4f4f4;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 8px 24px;
  border: 1px solid;
  border-color: #f4f4f4;
  border-radius: 12px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.customFormField {
  background-color: #f4f4f4;
  height: calc(100% + 2px);
  width: 100%;
  padding: 0;
  border: 0;
  outline: none;
  font-family: 'Poppins';
  color: #58585b;
  font-size: 12px;
  font-weight: 400;
  resize: none;
  overflow-wrap: normal;
  overflow-x: hidden;
}

.customFormField:focus {
  border-color: #58585b;
}

.customFormFieldError {
  border-color: #d35f5f;
  color: #d35f5f;
}

.customFormFieldError:focus {
  border-color: #d35f5f;
}

.optionalText {
  color: #d9d9d9;
  font-size: 12px;
  font-weight: 400;
}

.optionalText:hover {
  cursor: text;
}

.errorMessage {
  margin: 4px 0 4px 24px;
  color: #d35f5f;
  font-size: 8px;
}
