.clickable {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
}

.disable {
  pointer-events: none;
}

.disable img {
  filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1658%)
    hue-rotate(189deg) brightness(112%) contrast(70%);
}
