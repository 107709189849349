.customButton {
  height: 38px;
  padding: 10px 24px;
  border: 0;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5vw;
  transition: all 0.5s;
}

/* primary */
.primary {
  background-color: #d9ebe3;
  color: #4a8870;
}

.primary:hover {
  background-color: #4a8870;
  color: #ecf3f0;
}

.primary.disabledButton {
  background-color: #d9d9d9;
  color: #b0b0b0;
}

/* secondary */
.secondary {
  background-color: transparent;
  border: 1px solid;
  border-color: #4a8870;
  color: #4a8870;
}

.secondary:hover {
  background-color: #4a8870;
  color: #ecf3f0;
}

.secondary.disabledButton {
  background-color: #d9d9d9;
  border-color: #b0b0b0;
  color: #b0b0b0;
}

/* tertiary */
.tertiary {
  background-color: transparent;
  padding: 0;
  border: 0px solid transparent;
  border-bottom-width: 1px;
  border-radius: 0;
  color: #4a8870;
}

.tertiary:hover {
  border-color: #4a8870;
  color: #4a8870;
}

.tertiary.disabledButton {
  background-color: transparent;
  border-color: transparent;
  color: #b0b0b0;
}

/* label */
.customButton .label {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

/* icon */
.customButton .arrow {
  height: 12px;
  width: 12px;
  filter: brightness(0) saturate(100%) invert(50%) sepia(38%) saturate(416%)
    hue-rotate(105deg) brightness(88%) contrast(89%);
  transition: all 0.5s;
}

.customButton.disabledButton .arrow,
.customButton.disabledButton:hover .arrow,
.customButton.disabledButton.dark .arrow,
.customButton.disabledButton.dark:hover .arrow {
  filter: brightness(0) saturate(100%) invert(82%) sepia(0%) saturate(0%)
    hue-rotate(169deg) brightness(89%) contrast(84%);
}

/* primary arrow, secondary arrow */
.primary:hover .arrow,
.secondary:hover .arrow {
  filter: brightness(0) saturate(100%) invert(98%) sepia(29%) saturate(90%)
    hue-rotate(74deg) brightness(98%) contrast(94%);
}

/* dark */
/* dark primary */
.primary.dark {
  background-color: #ffffff;
  color: #4a8870;
}

.primary.dark:hover {
  background-color: #eee9b6;
}

.primary.disabledButton.dark {
  background-color: #d9d9d9;
  color: #b0b0b0;
}

/* dark secondary */
.secondary.dark {
  background-color: transparent;
  border: 1px solid;
  border-color: #ffffff;
  color: #ffffff;
}

.secondary.dark:hover {
  background-color: #eee9b6;
  border-color: #eee9b6;
  color: #4a8870;
}

.secondary.disabledButton.dark {
  background-color: #d9d9d9;
  border-color: #b0b0b0;
  color: #b0b0b0;
}

/* dark tertiary */
.tertiary.dark {
  color: #ffffff;
}

.tertiary.dark:hover {
  border-color: #ffffff;
  color: #ffffff;
}

.tertiary.disabledButton.dark,
.tertiary.disabledButton.dark:hover {
  color: #b0b0b0;
  border-color: transparent;
}

/* icon */
.primary.dark .arrow,
.primary.dark:hover .arrow,
.secondary.dark:hover .arrow {
  filter: brightness(0) saturate(100%) invert(51%) sepia(26%) saturate(583%)
    hue-rotate(105deg) brightness(87%) contrast(89%);
}

.secondary.dark .arrow,
.tertiary.dark .arrow,
.tertiary.dark:hover .arrow {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(143deg) brightness(103%) contrast(102%);
}
