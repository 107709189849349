.textBtn {
  background: #fffffa;
  color: #4a8870;
  padding: 6px;
  border: 1px solid #4a8870;
  border-radius: 12px;
  transition: all 0.5s;
}

.textBtn:hover,
.textBtn:focus {
  background: #4a8870;
  color: white;
}
