/* Header Styles */
.headerContainer {
  margin-left: -6vw;
  margin-top: -4vh;
  padding: 4vh 6vw;
  width: 100vw;
  background-color: #f6efec;
  margin-bottom: 88px;
  overflow: hidden;
}

.title {
  position: relative;
  z-index: 2;
}

.titleDescriptionContainer {
  z-index: 2;
}

.titleDescription {
  margin-top: 64px;
  margin-bottom: 76px;
  max-width: 456px;
}

.headerImageContainer {
  position: relative;
  min-height: 370px;
}

.applyBHImage {
  position: absolute;
  top: -50px;
  z-index: 2;
}

.applyBHBackgroundImage1 {
  position: absolute;
  top: -100px;
  left: -8vw;
  height: 500px;
  width: 100%;
  z-index: 1;
}

.applyBHBackgroundImage2 {
  position: absolute;
  top: -150px;
  left: -35vw;
  z-index: 0;
  width: 200%;
}

/* Description Styles */
.descriptionTitle {
  font-size: 24px;
  margin-bottom: 48px;
}

.berbagiHatiReasonContainer {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.berbagiHatiReason {
  color: #38383c;
}

.berbagiHatiReasonDescription {
  color: #808284;
}

/* Application Cycle Styles */
.applicationCycleContainer {
  margin-top: 96px;
  margin-left: -6vw;
  padding: 10vh 6vw;
  width: 100vw;
  position: relative;
  display: flex;
}

.applicationCycleContentContainer {
  z-index: 1;
}

.applicationCycleBackground {
  position: absolute;
  top: 0;
  left: -6vw;
  width: 106vw;
  height: 100%;
  z-index: 0;
}

.applicationCycleTitle {
  font-size: 14px;
  font-weight: 500;
}

.applicationTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 48px;
}

.applicationCycleDescription {
  margin-bottom: 80px;
}

/* Success Stories Styles */
.successStoriesContainer {
  margin-top: 64px;
}

.successStoriesTitle {
  margin-bottom: 64px;
  font-size: 24px;
  font-weight: 700;
}

.carouselItemContainer {
  display: flex;
  margin-bottom: 64px;
  gap: 5%;
}

.clientContainer {
  border: 2px solid #B4D1C5;
  border-radius: 24px;
  width: 25%;
  height: 510px;
  overflow: hidden;
}

.clientProfilePictureContainer {
  height: 40%;
}

.clientProfilePicture {
  width: 100%;
  height: 100%;
}

.clientContentContainer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
  background: white;
}

.clientTitle {
  margin-top: 16px;
  font-weight: 700;
  font-size: 16px;
}

.clientDescription {
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  height: 95px;
}

.clientReport {
  margin-top: 32px;
  font-weight: 700;
  font-size: 12px;
}

.clientReportLight {
  font-weight: 400;
}

.fundOthersContainer {
  background-color: #fafbf7;
  padding: 4vh 6vw;
}

.fundOthersImage {
  max-width: 100%;
  height: 300px;
}

.fundOthersTitle {
  margin-top: 40px;
  font-weight: 700;
  font-size: 24px;
}

.fundOthersDesc {
  margin-top: 40px;
  margin-bottom: 70px;
  font-weight: 400;
  font-size: 16px;
}

/* TODO: remove linked button after we have a linked button component */
.linkedButton {
  display: inline-block;
}

.linkedButton:hover {
  text-decoration: none;
}

/* Small Laptops */
@media only screen and (max-width: 1199px) {
  .applyBHImage {
    top: 0;
    width: 100%;
    height: 100%;
  }

  .applyBHBackgroundImage1 {
    top: 0;
    height: 100%;
  }
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .applyBHBackgroundImage2 {
    top: -10px;
    left: -6vw;
    height: 100%;
    width: 100%;
  }

  .applicationCycleContainer {
    margin-top: 15px;
    border-top: 1px solid #4a8870;
    border-bottom: 1px solid #4a8870;
  }

  .fundOthersImage {
    margin-bottom: 90px;
  }

  .clientContainer {
    width: 320px;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .headerContainer,
  .descriptionTitle,
  .berbagiHatiReasonTitle,
  .applicationTitle,
  .applicationCycleDescription {
    margin-bottom: 15px;
  }

  .applyBHImage {
    width: 100%;
  }

  .applyBHBackgroundImage1 {
    left: 0;
  }

  .titleDescription {
    margin: 15px 0;
  }

  .berbagiHatiReasonContainer {
    gap: 15px;
  }
}
