.profileContainer {
  padding: 4vh 6vw;
  background-color: #F9F9F1;
}

/* back to clients button */
.iconWithText {
  display: flex;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;
  margin-bottom: 24px;
  width: fit-content;
}

.iconWithText img {
  width: 16px;
}

.iconWithText div {
  font-size: 16px;
  font-weight: 400;
}
/* back to clients button end */

.profileHeader {
  margin-top: 24px;
  margin-bottom: 64px;
  word-break: break-word;
}

.profilePictureContainer {
  max-width: 360px;
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  overflow: hidden;
}

.profilePictureDescription {
  padding: 24px;
  background-color: white;
}

.profilePicture {
  width: 100%;
  height: 360px;
}

.statusContainer {
  padding: 10px 20px;
  border-radius: 85px;
  width: fit-content;
  margin-bottom: 40px;
}

.defaultClientStatus {
  background-color: #D9D9D9;
  color: #808284;
}

.clientStatusTreatmentInProgress {
  background-color: #A7D59B;
  color: #F9F9F1;
}

.clientStatusOnHold {
  background-color: #D35F5F;
  color: #F9F9F1;
}

.advertisement {
  margin-bottom: 46px;
}

.progressText {
  margin-top: 14px;
  margin-bottom: 24px;
}

.numberOfSponsors {
  color: #B0B0B0;
  margin-bottom: 64px;
}

.donationFormContainer {
  gap: 15px;
  margin: 0 0 70px 0;
}

.donateBtnContainer {
  display: flex;
  justify-content: end;
}

.donateTextOption {
  padding: 6px;
}

.detailContainer {
  padding: 4vh 6vw;
}

.story {
  white-space: pre-wrap;
}

.story, .costBreakdown, .updates {
  padding-top: 72px;
}

.clientUpdateContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 0.5px solid #D9D9D9;;
}

.clientUpdateContainer:last-child {
  border: none;
}

.clientDescriptionContainer {
  width: 70%;
}

.clientUpdateTitle {
  font-weight: 500;
  font-size: 16px;
}

.clientUpdateDescription {
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
}

.clientUpdateDate {
  width: 30%;
  font-size: 400;
  font-size: 12px;
  color: #B0B0B0;
  text-align: right;
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .profilePictureContainer {
    margin-bottom: 46px;
  }
}
