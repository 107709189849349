.textHeader {
  margin-bottom: 48px;
  font-size: 24px;
  font-weight: 700;
}

.textSubHeader {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
}

.textContent {
  font-size: 16px;
  font-weight: 400;
}

/* Description */
.descriptionContainer {
  background: linear-gradient(180deg, #f4f4f4 0%, rgba(187, 189, 192, 0) 100%);
  min-height: 464px;
  width: 56vw;
  margin-top: 48px;
  border-radius: 0 20px 20px 0;
  position: relative;
  left: -6vw;
}

.descriptionText {
  padding: 40px 246px 40px 6vw;
}

.descriptionImage {
  height: 100%;
  width: 48vw;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 44vw;
}

.descriptionImage > img {
  width: 100%;
  margin: 0;
}

/* Instruction */
.instructionContainer {
  margin-top: 50px;
  background-color: #f4f4f4;
}

.submission {
  margin-top: 64px;
}

.instruction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
}

.instruction:first-child {
  align-items: flex-start;
}

.instruction > div {
  height: 100%;
}

.highlight {
  color: #808284;
}

/* toolTip */
.toolTipIcon {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.toolTipSvg {
  height: 56px;
  width: 56px;
}

.toolTipSvg:hover {
  fill: #38383c;
}

.toolTipSvg:hover path {
  fill: #f4f4f4;
}

.toolTipContainer {
  width: 360px;
  max-width: 62.5vw;
  padding: 0;
  position: absolute;
  bottom: 80px;
  left: 0;
  z-index: 1;
}

.toolTipContainer img {
  height: 24px;
}

.toolTipHeader {
  background-color: #4a8870;
  height: fit-content;
  padding: 16px 32px;
  margin: 0;
  border: 2px solid #4a8870;
  border-radius: 20px 20px 0 0;
  box-sizing: border-box;
}

.toolTipHeader > div:first-child {
  padding: 0;
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.toolTipHeader > div:last-of-type {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.toolTipContent {
  background-color: white;
  padding: 24px 32px;
  margin: 0;
  border: 2px solid #4a8870;
  border-radius: 0 0 20px 20px;
  box-sizing: border-box;
}

.toolTipContent > div {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 14px;
  font-weight: 400;
}

.toolTipContent ol {
  padding-left: 14px;
  margin: 0;
}

/* Stories */
.grid {
  margin-bottom: 64px;
}

.gridImageContainer {
  padding: 16px;
}

.gridImageContainer img {
  width: 100%;
  cursor: pointer;
}

/* spinner */
.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinnerHeight {
  height: 100px;
}

.spinnerWidth {
  width: 100px;
}

.spinnerBorder {
  border-width: 7px;
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .descriptionContainer {
    width: 100%;
    border-radius: 20px;
    left: 0;
    background: #f4f4f4;
  }

  .descriptionText {
    padding: 40px 48px;
  }

  .descriptionImage {
    width: 100%;
    margin-left: 0;
    margin-bottom: 40px;
    position: static;
  }

  .instruction:first-child {
    flex-direction: row;
  }

  .toolTipContainer {
    left: -304px;
    top: 80px;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .descriptionTextHeader {
    font-size: 21px;
  }

  .descriptionTextContent {
    font-size: 14px;
  }

  .submission {
    gap: 70px;
  }

  .toolTipContainer {
    left: calc(-62.5vw + 56px);
  }
}
