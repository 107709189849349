.providerSubtitle {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
}

.providerDetail {
  display: grid;
  grid-template-areas:
    'profile name'
    'profile abouts'
    'contacts abouts';
  column-gap: 64px;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto auto 5fr;
}

.providerProfilePicture {
  grid-area: profile;
  width: 100%;
  margin-bottom: 48px;
}

.nameWrapper {
  grid-area: name;
  margin-bottom: 56px;
}

.providerContacts {
  grid-area: contacts;
}

.providerAbouts {
  grid-area: abouts;
}

/* back to providers button */
.iconWithText {
  width: fit-content;
  margin-bottom: 64px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;
}

.iconWithText img {
  width: 16px;
}

.iconWithText div {
  font-size: 16px;
  font-weight: 400;
}

/* section one */

/* contact */
.contactWrapper {
  margin-bottom: 64px;
}

.contactDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contactInfoWrapper {
  display: flex;
  gap: 25px;
}

.contactInfoIcon {
  width: 20px;
  height: 20px;
  fill: #38383c;
}

.contactInfoText {
  font-size: 16px;
  font-weight: 400;
}

/* location */
.locationWrapper {
  margin-bottom: 40px;
}

.locationInfoIcon {
  width: 12px;
  height: 12px;
}

.locationInfoAddress {
  display: flex;
  gap: 22px;
  align-items: center;
}

/* section two */
.providerDetailTwoWrapper {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

/* licensure */
.licensureText {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
}

/* name */
.nameText {
  font-size: 36px;
  font-weight: 700;
  word-break: break-word;
}

/* specialty */
.specialtyDetails {
  padding-left: 15px;
}

/* treatment */
.treatmentDetails {
  padding-left: 15px;
}

.treatmentInfoIcon {
  width: 20px;
  height: 20px;
}

/* insurance */
.insuranceDetails {
  display: flex;
  align-items: center;
  gap: 25px;
}

.insuranceInfoIcon {
  width: 12px;
}

/* Tablet */
@media only screen and (max-width: 991px) {
  .providerDetail {
    grid-template-columns: 1fr 8fr;
  }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
  .providerDetail {
    grid-template-areas:
      'profile'
      'name'
      'contacts'
      'abouts';
    grid-template-columns: 1fr;
    gap: 46px;
  }

  .providerProfilePicture {
    width: 230px;
    height: 230px;
    margin-bottom: 0;
  }

  .nameWrapper {
    margin-bottom: 0;
  }
}
