.labeledInputContainer {
  display: flex;
  margin-bottom: 0; /* Note: cancels the default label margin */
}

.labeledInputLabel {
  background: #4a8870;
  color: white;
  border: 1px solid #4a8870;
  text-align: center;
  padding: 6px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.labeledInput {
  border: 1px solid #4a8870;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  font-size: 14px;
  outline-width: 0; /*Note: cancels the default border color on focus */
}

.labeledInputError {
  border-color: #d35f5f;
}

.errorMessage {
  position: absolute;
  margin: 4px 0 4px 24px;
  color: #d35f5f;
  font-size: 8px;
}
